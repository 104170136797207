import "./logger-init";

import ReactDOM from "react-dom/client";
import "./index.css";
import { initMonitors } from "./initializers";
import { env } from "./utils/env";
import { App } from "./App";
import { getEaLogger } from "./state";

initMonitors();

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(<App />);

getEaLogger().child({ module: "index" }).info(`Config was loaded for '${env.envName}' environment.`);
