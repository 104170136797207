import { Flex } from "@mantine/core";
import { LottieAnimator } from "../../animations/LottieAnimator";
import classes from "./styles.module.css";
import loader from "./WorkspaceLoader.lottie?url";

export function WorkspaceLoading() {
    return (
        <Flex direction="unset" justify="center" align="center">
            <LottieAnimator className={classes.workspaceLoadingAnimation} src={loader} />
        </Flex>
    );
}
