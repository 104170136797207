import { type SendJsonMessage, getAppName } from "@expert/gaia";
import type { TimelineFeatures } from "@expert/features";

interface FetchMessageHistoryProps {
    sessionId: string;
    partner: string;
    features: Pick<
        TimelineFeatures,
        | "isOzmoMessagingEnabled"
        | "isReactiveCallContextEnabled"
        | "isReactiveCallContextV2Enabled"
        | "isSalesFAQEnabled"
    >;
    sendJsonMessage: SendJsonMessage;
}

export function fetchMessageHistory({
    sessionId,
    partner,
    features: {
        isOzmoMessagingEnabled,
        isReactiveCallContextEnabled,
        isReactiveCallContextV2Enabled,
        isSalesFAQEnabled,
    },
    sendJsonMessage,
}: FetchMessageHistoryProps) {
    const appName = getAppName({
        isOzmoMessagingEnabled,
        isReactiveCallContextEnabled,
        isReactiveCallContextV2Enabled,
        isSalesFAQEnabled,
    });
    const fetchMessageHistoryPayload = {
        action: "fetch-orchestrator-history",
        appName,
        correlationId: crypto.randomUUID(),
        partner,
        sessionGroupId: sessionId,
    };
    sendJsonMessage(fetchMessageHistoryPayload);
}
