import { createBrowserRouter } from "react-router";
import { LandingPage } from "../pages";
import { ProtectedHome } from "./ProtectedRoute";
import { RoutePath } from "./RoutePath";
import { ErrorBoundaryLayout } from "./ErrorBoundaryLayout";

export const router = createBrowserRouter([
    {
        element: <ErrorBoundaryLayout />,
        children: [
            { path: RoutePath.Landing, Component: LandingPage },
            { path: RoutePath.Index, Component: LandingPage },
            { path: RoutePath.Home, Component: ProtectedHome },
            { path: RoutePath.All, Component: ProtectedHome },
        ],
    },
]);
