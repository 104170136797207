import { create } from "zustand";
import { createJSONStorage, devtools, persist, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { Logger } from "@expert/logging";
import { noteColorGroups } from "./consts";
import type { ColorGroup, Coordinates, Location, Size } from "./types";

export interface ExpertNoteStore {
    note: string;
    setNote: (note: string) => void;

    notepadLocation: Location;
    setNotepadLocation: (location: Location, logger: Logger) => void;

    draggableBounds: Coordinates;
    setDraggableBounds: (coordinates: Coordinates) => void;

    notepadSize: Size;
    setNotepadSize: (size: Size) => void;

    color: ColorGroup;
    setColor: (color: ColorGroup) => void;
}

export const useExpertNoteStore = create<ExpertNoteStore>()(
    subscribeWithSelector(
        immer(
            persist(
                devtools(
                    (set) => ({
                        note: "",
                        setNote: (note: string) => {
                            set(
                                (state) => {
                                    state.note = note;
                                },
                                false,
                                "setNote",
                            );
                        },
                        notepadLocation: { x: 100, y: 100 },
                        setNotepadLocation: (location: Location, logger?: Logger) => {
                            set(
                                (state) => {
                                    logger?.info(`setting notepad location`);
                                    state.notepadLocation = location;
                                },
                                false,
                                "setNotepadLocation",
                            );
                        },
                        draggableBounds: { x1: 0, y1: 0, x2: 0, y2: 0 },
                        setDraggableBounds: (coordinates: Coordinates) => {
                            set((state) => {
                                state.draggableBounds = coordinates;
                            });
                        },
                        notepadSize: { width: 300, height: 300 },
                        setNotepadSize: (size: Size) => {
                            set(
                                (state) => {
                                    state.notepadSize = size;
                                },
                                false,
                                "setNotepadSize",
                            );
                        },
                        color: noteColorGroups[1],
                        setColor: (color: ColorGroup) => {
                            set(
                                (state) => {
                                    state.color = color;
                                },
                                false,
                                "setColor",
                            );
                        },
                    }),
                    { enabled: import.meta.env.MODE !== "production", store: "expert-note", name: "note" },
                ),
                {
                    name: "expert-note-storage",
                    storage: createJSONStorage(() => sessionStorage),
                    partialize: (state) => ({
                        note: state.note,
                        color: state.color,
                        notepadLocation: state.notepadLocation,
                        notepadSize: state.notepadSize,
                    }),
                },
            ),
        ),
    ),
);
