import { useEffect } from "react";
import type { GaiaWebSocketResponse } from "@expert/gaia";
import { gaiaWsEventBus } from "@expert/gaia";
import type { ReactiveMessagePayload, GaiaErrorMessage } from "@expert/shared-types";
import { handleIncomingMessage, addErrorMessage } from "@soluto-private/expert-workspace-timeline";
import { useLoggerWithContextStore } from "../state";

export const useGaiaMessageEvents = () => {
    const { loggerWithContext } = useLoggerWithContextStore();
    useEffect(() => {
        // Reactive Message Handling
        const gaiaEventBusMessageSuccess = gaiaWsEventBus.on(
            "gaia_ws_orchestrator-invoke-success",
            ({ body }: GaiaWebSocketResponse) => {
                handleIncomingMessage(body as ReactiveMessagePayload, loggerWithContext);
            },
        );

        const gaiaEventBusOrchestratorFailure = gaiaWsEventBus.on("gaia_ws_orchestrator-invoke-error", (response) => {
            addErrorMessage("botFailure");
            //eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { sessionDetails, ...rest } = response as unknown as GaiaErrorMessage;
            loggerWithContext.error(
                {
                    module: "useGaiaMessageEvents",
                    errorBody: rest,
                },
                "GAIA websocket error message response",
            );
        });

        // NOTE: We no longer support Proactive Messages in Expert Assist
        // We can enable this again if prioritized using the GAIA websocket & orchestrator

        return () => {
            gaiaEventBusMessageSuccess();
            gaiaEventBusOrchestratorFailure();
        };
    }, [loggerWithContext]);
};
