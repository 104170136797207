import { type GaiaWebSocketResponse, gaiaWsEventBus } from "@expert/gaia";
import type { Logger } from "@expert/logging";
import { useEffect, useMemo } from "react";
import { type GaiaErrorMessage, type HistoricMessage, type MessageHistoryPayload } from "@expert/shared-types";
import { addMessages } from "../state";
import { type ExpertAssistMessage } from "../shared-types";

const NoMessageHistoryStatus = 404;

export const useFetchMessageHistoryEvents = (loggerProp: Logger) => {
    const logger = useMemo(() => loggerProp.child({ module: "useFetchMessageHistory" }), [loggerProp]);

    useEffect(() => {
        const fetchMessageHistorySuccess = gaiaWsEventBus.on(
            "gaia_ws_fetch-orchestrator-history-success",
            ({ body }: GaiaWebSocketResponse) => {
                const payload = body as Partial<MessageHistoryPayload>;
                const messages = payload.messages;
                if (messages?.length) {
                    logger.debug(
                        { sessionId: payload.sessionGroupId, messageCount: messages.length },
                        "GAIA | Successfully fetched message history",
                    );
                    addMessageHistoryToTimeline(messages);
                }
            },
        );

        const fetchMessageHistoryFailure = gaiaWsEventBus.on("gaia_ws_fetch-orchestrator-history-error", (response) => {
            const { status } = response as Partial<GaiaErrorMessage>;
            if (status === NoMessageHistoryStatus) {
                logger.debug({ response }, "GAIA | No message history for session");
            } else {
                logger.error({ response }, "GAIA | Fetch message history error");
            }
        });

        return () => {
            fetchMessageHistorySuccess();
            fetchMessageHistoryFailure();
        };
    }, [logger]);
};

export const addMessageHistoryToTimeline = (messages: HistoricMessage[]) => {
    if (messages.length > 0) {
        const previousMessages = messages.map<ExpertAssistMessage>(
            ({ id, content, role, timestamp }: HistoricMessage) => ({
                text: content,
                type: role === "assistant" ? "Search" : "Expert",
                id,
                isUnread: false,
                status: "active",
                timestamp,
            }),
        );
        addMessages(previousMessages);
    }
};
